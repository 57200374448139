import React from 'react';
import Example from '../assets/team/imgs/example.png';
import Max from '../assets/team/imgs/Max.jpeg';
import Img3 from '../assets/team/imgs/Nico.jpg';
import Img5 from '../assets/team/imgs/Belen2.jpg';
import Catota from '../assets/team/imgs/catota.jpeg';
import Jair from '../assets/team/imgs/Jair.jpg';
import Yuyo from '../assets/team/imgs/yuyo.jpg';
import Fredo from '../assets/team/imgs/fredo.jpeg';

import GifExample from '../assets/team/gifs/example.png';
import GifExample2 from '../assets/team/gifs/example_2.png';
import Luigi from '../assets/team/gifs/luigi.gif';
import MyMelody from '../assets/team/gifs/my-melody-tea.gif';
import Gif5 from '../assets/team/gifs/naruto.gif';
import Gif6 from '../assets/team/gifs/letsgo.gif';
import Gif7 from '../assets/team/gifs/goku.gif';
import Kirby from '../assets/team/gifs/kirby.gif';
import Mudkip from '../assets/team/gifs/mudkip.gif';
import Aang from '../assets/team/gifs/aang.gif';

import '../TeamCss.css';

interface Member {
  name: string;
  role: string;
  image: string;
  gif: string;
  text: string;
  gradient: string;
}

const team: Member[] = [
  {
    name: 'Max Narea',
    role: 'CEO | Ingeniero de Software',
    image: Max,
    gif: Gif6,
    text: '',
    gradient: 'from-[#003DDA] via-[#0EEA4B] to-[#B5F20C]',
  },
  {
    name: 'Nicolás Jimenez',
    role: 'Ingeniero de Software',
    image: Img3,
    gif: Gif7,
    text: '',
    gradient: 'from-[#F20C28] via-[#EA8F0E] to-[#1129EA]',
  },
  {
    name: 'Catalina Narea',
    role: 'Encargada de Finanzas',
    image: Catota,
    gif: Luigi,
    text: '',
    gradient: 'from-[#0EEA4B] to-[#F20C28]',
  },
  {
    name: 'Jair Zuñiga',
    role: 'Diseñador UX/UI | Gestor de Proyectos y Éxito',
    image: Jair,
    gif: Kirby,
    text: '',
    gradient: 'from-[#1129EA] to-[#B5F20C]',
  },
  {
    name: 'Belén Silva',
    role: 'Ingeniera de Software | Gestora de Proyectos y Éxito',
    image: Img5,
    gif: MyMelody,
    text: '',
    gradient: 'from-[#B5F20C] to-[#F20C28]',
  },
  {
    name: 'Alfredo Manhs',
    role: 'Ingeniero de Software',
    image: Fredo,
    gif: Aang,
    text: '',
    gradient: 'from-[#F20C28] to-[#0EEA4B]',
  },
  {
    name: 'Alfredo Llanos',
    role: 'Ingeniero de Software',
    image: Yuyo,
    gif: Mudkip,
    text: '',
    gradient: 'from-[#0EEA4B] to-[#F20C28]',
  },
];

const Team: React.FC = () => {
  return (
    <section className="w-full min-h-screen bg-[#050505] pt-40 pb-10 flex flex-col items-center">
      {/* Título */}
      <div className="flex flex-col items-center w-full mb-10">
        <span className="text-[30px] md:text-3xl text-white mb-2">
          CONOCE A
        </span>
        <p className="text-4xl md:text-6xl text-white font-bold">
          NUESTRO{' '}
          <strong className="italic font-black text-yellow">EQUIPO</strong>
        </p>
      </div>

      {/* Contenedor de los miembros en formato grid */}
      <div className="w-full max-w-[90%] mx-auto px-2">
      <div className="flex flex-wrap gap-10 justify-center">
  {team.map((member) => (
    <div
      key={member.name}
      className="w-72 relative flex flex-col items-center shadow-lg group"
    >
      {/* Imagen estática y GIF en hover */}
      <div className="relative overflow-hidden w-72 h-72">
        <img
          src={member.image}
          alt={member.name}
          className="w-full h-full object-cover rounded-lg transition-all duration-500 group-hover:opacity-0"
        />
        <img
          src={member.gif}
          alt={`${member.name} gif`}
          className="absolute top-0 left-0 w-full h-full object-cover rounded-lg transition-all duration-500 opacity-0 group-hover:opacity-100"
        />
      </div>

      {/* Texto hover si hiciera falta */}
      <div className="absolute top-0 flex justify-center items-center opacity-0 group-hover:opacity-100 transition-all duration-500">
        <p className="italic font-black text-white text-xl text-left py-5 pl-4 pr-24">
          {member.text}
        </p>
      </div>

      {/* Nombres y roles */}
      <p
        className={`
          text-white
          text-2xl
          md:text-3xl
          font-semibold
          mt-5
          pl-2
          group-hover:text-transparent
          bg-clip-text
          bg-gradient-to-r
          ${member.gradient}
        `}
      >
        {member.name}
      </p>
      <p className="text-white text-xl text-center">
        {member.role}
      </p>
    </div>
  ))}
</div>

      </div>
    </section>
  );
};

export default Team;
